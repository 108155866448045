.dark,
.light {
  @apply block;
}

.dark {
  height: 103px;
  width: 165px;
}

.light {
  width: 120px;
  height: 75px;
}

.dark .name {
  @apply fill-green;
}

.dark .logo {
  @apply fill-blue;
}

.dark .dot {
  @apply fill-green;
}

.light .name {
  @apply fill-green;
}

.light .logo {
  @apply fill-gray-200;
}

.light .dot {
  @apply fill-green;
}
