.dropdown {
  @apply hidden;
}

.offices,
.rooms {
  @apply w-full lg:w-[700px];
}

.offices .main,
.rooms .main {
  @apply md:w-3/5;
}

.offices .sidebar,
.rooms .sidebar {
  @apply md:w-2/5;
}

.services {
  @apply w-full lg:w-[450px];
}

.about {
  @apply w-full lg:w-[450px];
}

.dropdown.active {
  @apply block lg:absolute lg:top-12 xl:top-8;
}

.dropdown.active.about {
  @apply lg:-left-36
}

.dropdown.active.about .arrow  {
  @apply lg:left-36
}

.window {
  @apply p-5 lg:rounded-xl bg-white/95 lg:drop-shadow-lg;
  /* box-shadow: 5px 6px 8px rgba(var(--color-black) / 60%); */
}

.window ul {
  @apply list-none m-0;
}

.wrap {
  @apply flex;
}

.sidebar {
  @apply pl-3 sm:pl-6 ml-3 sm:ml-6 border-l;
}

.footer {
  @apply text-left md:text-center mt-3 pt-3 border-t border-gray-300 text-xs flex flex-col gap-1;
}

.arrow {
  @apply hidden lg:block w-0 h-0 ml-5 relative drop-shadow;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgb(var(--color-white));
}
