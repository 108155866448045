.header {
  @apply pt-4 lg:pt-10 pb-4;
}

.logo_wrap {
  @apply mb-6 lg:mr-8 lg:mb-0 block;
}

.logo_wrap svg {
  @apply m-auto;
}

.nav {
  @apply -mx-5 lg:mx-0 p-4 lg:p-0 lg:w-[calc(100%-165px-2rem)] relative flex flex-row-reverse lg:flex-col justify-around lg:justify-between bg-blue lg:bg-transparent;
}

.nav_top {
  @apply hidden lg:flex lg:items-center lg:justify-between lg:mb-6;
}

.nav_top div {
  @apply hidden lg:flex;
}

.nav_top_active {
  @apply absolute block lg:relative top-14 lg:top-0 lg:flex w-full py-4 px-8 lg:p-0 text-center bg-gray-100 lg:bg-transparent;
}

.nav_top a {
  @apply fill-blue hover:fill-green transition-colors ease-in-out duration-300;
}

.nav_top svg {
  @apply inline-block;
}

.nav_top_home {
  @apply hidden lg:block;
}

.nav_top_link {
  @apply flex justify-center items-center text-sm text-gray;
}

.nav_top_link svg {
  @apply mr-2;
}

.contact_btn {
  @apply flex items-center lg:hidden text-white text-sm hover:text-green transition-colors ease-in-out duration-300;
}

.contact_btn svg {
  @apply fill-white transition-colors ease-in-out duration-300;
}

.contact_btn span {
  @apply inline-block ml-2
}

.contact_btn:hover svg {
  @apply fill-green;
}

