.menu_btn {
  @apply flex items-center lg:hidden text-white text-sm hover:text-green transition-colors ease-in-out duration-300;
}

.menu_btn svg {
  @apply fill-white transition-colors ease-in-out duration-300;
}

.menu_btn span {
  @apply inline-block ml-2;
}

.menu_btn:hover svg {
  @apply fill-green;
}

.menu {
  @apply m-0 lg:w-auto absolute lg:relative z-50 top-14 lg:top-auto left-0 lg:left-auto right-0 lg:right-auto lg:flex lg:gap-1 py-4 lg:py-0 bg-gray-100 lg:bg-transparent;
}

.menu > li {
  @apply relative lg:w-full lg:flex lg:content-between;
}

.menu > li > a {
  @apply lg:w-full p-1.5 py-2 lg:py-3 block lg:flex lg:justify-center lg:items-center text-left lg:text-center text-sm lg:text-xs lg:rounded-lg text-gray-300 hover:text-white lg:text-white bg-gray-100 hover:bg-gray-300 lg:bg-blue lg:hover:bg-green uppercase lg:hover:shadow-md transition-colors ease-in-out duration-300 leading-tight;
}

.menu > li > button {
  @apply w-full px-5 lg:px-4 py-2 lg:py-3 block lg:flex lg:justify-center lg:items-center text-left lg:text-center text-sm lg:text-xs lg:rounded-lg text-gray-300 hover:text-white lg:text-white bg-gray-100 hover:bg-gray-300 lg:bg-blue lg:hover:bg-green uppercase lg:hover:shadow-md transition-colors ease-in-out duration-300 leading-tight;
}

.menu > li > a.highlight {
  @apply lg:bg-green lg:hover:bg-blue;
}

.menu > li > button.highlight {
  @apply lg:bg-green lg:hover:bg-blue;
}

.title {
  @apply text-xs font-bold uppercase text-blue mb-1 sm:mb-2 leading-tight sm:whitespace-nowrap;
}

.main_ul {
  @apply sm:flex sm:flex-wrap sm:justify-between;
}

.main_list {
  @apply sm:w-[calc((100%/3)-.5rem)] sm:border-r border-gray-300 sm:mr-2 sm:pr-2;
}
.main_list:last-child {
  @apply border-0 mr-0 pr-0;
}

.main_list a {
  @apply text-xs block mb-2 md:mb-0;
}
