.form {
  /* @apply order-1 md:order-2 w-full md:ml-8 lg:ml-12 border-b mb-12 md:mb-0 pb-12 md:pb-0 md:border-none border-white; */
}

.form h3 {
  @apply text-blue text-lg lg:text-2xl font-normal font-body mb-4;
}

.form form {
  @apply relative bg-white shadow-lg rounded-lg py-4 px-4 md:px-8;
}

.form_row {
  @apply flex flex-col sm:flex-row md:flex-col lg:flex-row sm:justify-between gap-0 sm:gap-6 md:gap-0 lg:gap-6;
}

.form_item {
  @apply w-full mb-4;
}

.form_item label {
  @apply text-blue text-sm w-full block mb-1 lg:mb-2;
}

.form button[type="submit"] {
  @apply rounded-lg text-white hover:text-white focus:text-white font-medium uppercase inline-flex items-center hover:shadow-md transition-colors ease-in-out duration-300 bg-blue hover:bg-green focus:ring-blue px-5 py-2 text-xs xl:text-sm absolute right-4 md:right-8 -bottom-5;
}

.input_wrap {
  @apply relative mt-2;
}

.input_wrap svg {
  @apply fill-blue absolute top-1;
}

.input_wrap input,
.input_wrap select {
  @apply w-full border-b-2 border-gray-200 focus:border-green outline-none font-light pl-5;
}

.input_wrap input.error {
  @apply border-red;
}

.input_wrap textarea {
  @apply w-full border-b-2 border-gray-200 focus:border-green outline-none font-light pl-5;
}

.input_error {
  @apply text-red text-sm block mt-2;
}

.address {
  @apply hidden;
}

.postcode {
  @apply hidden;
}

.success {
  @apply bg-white rounded-lg py-4 px-4 md:px-8 text-blue text-center text-xl;
  box-shadow: -3px 3px 6px #00000067;
}

.details {
  @apply md:pr-8 lg:pr-12 md:border-r border-white order-2 md:order-1 w-full;
}

.details div {
  @apply border-none md:border pb-0 md:border-b md:border-white lg:pb-6 mb-4 md:mb-6;
}

.details div:last-child {
  @apply border-none pb-0 mb-0;
}

.details div h3 {
  @apply text-white text-lg lg:text-2xl font-normal font-body;
}

.details div a {
  @apply text-blue text-lg md:text-2xl font-medium font-body;
}

.details div:last-child h3 {
  @apply text-base font-normal;
}

.details div address {
  @apply text-blue not-italic;
}
