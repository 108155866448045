.form_item {
  @apply w-full mb-4;
}

.form_item label span {
  @apply text-gray-200;
}

.input_wrap {
  @apply relative mt-2;
}

.input_wrap input {
  @apply w-52 border rounded-sm border-gray-200 bg-transparent focus:border-green outline-none font-light px-3 py-3;
}

button.submit {
  @apply rounded-md text-white hover:text-green focus:text-green font-medium uppercase hover:shadow-md transition-colors ease-in-out duration-300 bg-green hover:bg-white focus:bg-white focus:ring-blue px-4 py-2 text-xs;
}

.input_wrap input.error {
  @apply border-red;
}

.input_error {
  @apply text-red text-sm block mt-2;
}

.address {
  @apply hidden;
}

.postcode {
  @apply hidden;
}

.success {
  @apply bg-white rounded-lg py-4 px-4 md:px-8 text-blue text-center text-xl;
  box-shadow: -3px 3px 6px #00000067;
}
