.footer {
  @apply bg-blue text-gray-200 pt-14 pb-8 text-xs mt-auto;
}

.footer h3 {
  @apply text-white font-display text-lg mb-4 md:mb-6;
}

.footer a {
  @apply text-gray-200 hover:text-green cursor-pointer;
}

.footer button {
  @apply text-gray-200 hover:text-green cursor-pointer;
}

.footer p {
  @apply mt-4;
}

.footer address {
  @apply not-italic;
}

.first {
  @apply md:flex md:justify-between mb-6 lg:gap-6;
}

.col1 {
  @apply flex flex-col justify-between sm:w-1/4 lg:w-2/6;
}

.col1 a svg {
  @apply fill-gray-200;
}

.col1 > svg {
  @apply block mb-8 md:mb-0;
}

.col1 a:hover svg {
  @apply fill-green;
}

.col2 {
  @apply sm:w-1/4 lg:w-1/6 mt-10 md:mt-0;
}

.col2 ul {
  @apply m-0 list-none;
}

.col2 li {
  @apply leading-8 md:leading-6;
}

.col3 {
  @apply sm:w-1/4 lg:w-1/6 mt-10 md:mt-0;
}

.col4 {
  @apply sm:w-1/4 lg:w-2/6 mt-10 md:mt-0;
}

.final {
  @apply md:flex justify-between flex-none;
}

.highlight {
  @apply text-green;
}

.final div:first-child {
  @apply mb-4 md:mb-0;
}

.modal {
  @apply bg-transparent md:w-2/3 m-auto p-6 lg:p-12;
}

.modal_overlay {
  @apply fixed bg-white/75 inset-0 z-50 flex justify-center;
}

.modal .modal_close {
  @apply absolute right-6 md:right-12 top-6 md:top-12 bg-blue p-2 z-10;
}
